import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      color: "#945C44",
    },
  },
  heroWrapper: {
    width: "100vw",
    height: "66vh",
    overflow: "hidden",
    position: "relative",
  },
  heroImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "inherit",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#00000020",
    justifyContent: "center",
  },
  imageText: {
    zIndex: 2,
    textAlign: "center",
    "&.MuiTypography-root": {
      color: "#FFFFFF",
    },
  },
  text: {
    // background: "linear-gradient(to right, #ff00ff, #00ff00)",
    // WebkitBackgroundClip: "text",
    // color: "transparent",
    WebkitTextStroke: "2px #000000",
  },
  centerText: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: "80px 20px",
    gap: 16,
  },
  betterText: {
    "&.MuiTypography-root": {
      fontStyle: "italic",
      fontSize: 80,
      textAlign: "center",
    },
  },
}));

export default useStyles;
