import { useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";

import useStyles from "./styles";
import { AboutusImg } from "../../assets";
import { Dashboard } from "../../templates";

//===================================================
// 1.Main Component
//===================================================
const Aboutus = (props: any) => {
  // 1-1. useStyles *require
  const classes = useStyles();

  // 1-2. Store

  // Set the title to your desired text
  useEffect(() => {
    document.title = "About Us - Bliss Momos";
  }, []);

  return (
    <Dashboard>
      <Grid container spacing={2} className={classes.gridBox}>
        <Grid item xs={12} md={6} className={classes.smallImage}>
          <img
            src={AboutusImg}
            alt="about us pic"
            loading="eager"
            className={classes.image}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Container className={classes.description}>
            <Typography variant="body1" className={classes.infoText}>
              Bliss Momos Cafe, owned by Sunil Shrestha and Kalpana Shrestha, it
              is a culinary gem born from their journey as international
              students in Australia. Sunil's educational background in
              hospitality management, combined with his extensive experience in
              various restaurants and cafes, laid the foundation for this
              establishment in 2014.
              <br />
              <br />
              The name "Bliss Momos Cafe" reflects their Nepalese heritage, with
              "Bliss" conveying the ultimate happiness one experiences when
              savoring their delicious momos, which are Nepalese dumplings. In
              Nepal, momos hold a special place in the hearts of people of all
              ages, making them a beloved anytime treat available in various
              styles and flavors.
              <br />
              <br />
              At Bliss Momos Cafe, the focus is on serving finest coffee,
              breakfast and lunch that not only delights the taste buds but also
              evokes a sense of joy. What sets this cafe apart is its commitment
              to creating an intimate and welcoming atmosphere for customers.
              The team prides itself on providing a genuine hospitality
              experience, ensuring that every visit is not just a coffee and
              meal but a memorable journey in our cafe.
              <br />
              <br />
              So, whether you're a fan of momos or eager to explore the flavors
              of Nepal, Bliss Momos Cafe offers a delightful blend of culture,
              quality, and warm hospitality that makes every visit a truly
              blissful experience.
            </Typography>
            <div>
              <div className={classes.userInfo}>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Sunil Shrestha
                </Typography>
                <Typography variant="subtitle1">
                  &nbsp;/&nbsp;Owner &amp; Food and Hospitality Enthusiast
                </Typography>
              </div>
              <div className={classes.userInfo}>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Kalpana Shrestha
                </Typography>
                <Typography variant="subtitle1">
                  &nbsp;/&nbsp;Owner &amp; Food Enthusiast
                </Typography>
              </div>
            </div>
          </Container>
        </Grid>

        <Grid item xs={12} md={6} className={classes.gridImage}>
          <img
            alt="about us pic"
            src={AboutusImg}
            loading="eager"
            className={classes.image}
          />
        </Grid>
      </Grid>
    </Dashboard>
  );
};

//===================================================
// 2.Export
//===================================================
export default Aboutus;
