import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridBox: {
    padding: "0 16px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  description: {
    display: "flex !important",
    justifyContent: "flex-start",
    height: "100%",
    flexDirection: "column",
    gap: 48,
  },
  image: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  userInfo: {
    display: "flex !important",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  smallImage: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  gridImage: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  infoText: {
    fontSize: "17px !important",
  },
}));

export default useStyles;
